import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Img from "gatsby-image"


import style from "./styles/team.module.scss"

const Team = () => {
    const data = useStaticQuery(graphql`
      query {
        sonja: file(relativePath: { eq: "face--sonja.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        kristina: file(relativePath: { eq: "face--kristina.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    return (
        <section id="team" className={style.component}>
          <article className={style.bio}>
            <header className={style.header}>
              <div className={style.ident}>
                <h3 className={style.name}>Sonja Davis</h3>
                <p className={style.title}>Managing Partner, Founder</p>
              </div>
              <Img className={style.headshot} imgStyle={{objectPosition: `top center`}} alt="Sonja Davis headshot" fluid={data?.sonja?.childImageSharp?.fluid} />
            </header>
            <main className={style.copy}>
              <p>
                Sonja Davis is the founder and managing partner
                at RES Group NW LLC; a real estate firm specializing
                in Right of Way Acquisition and Relocation. 
                Her years of experience, enterprising spirit and 
                results-oriented work style has guided the formation
                and the continued success of the company.
              </p>
              <p>
                Sonja is an accomplished negotiator who understands
                the importance of working with all the stakeholders'
                interests in mind.  Combined with her ability to
                collaborate with owners, attorneys, engineers, and
                agencies, to achieve the end goals that meets/exceeds
                everyone's expectations.  "I love what I do, and I
                believe in the process, and I believe my job is
                making an impact for the whole community."

              </p>
            </main>
            <footer>
              <ul className={style.certs}>
                <li>BS, Political Science</li>
                <li>Real Estate Managing Broker</li>
                <li>R/W-RAC, Right of Way Relocation Assistance</li>
                <li>WSDOT Approved Consultant</li>
              </ul>
            </footer>
          </article>
          <article className={style.bio}>
            <header className={style.header}>
              <div className={style.ident}>
                <h3 className={style.name}>Kristina Guzman</h3>
                <p className={style.title}>Partner</p>
              </div>
              <Img className={style.headshot} imgStyle={{objectPosition: `top center`}} alt="Kristina Guzman headshot" fluid={data?.kristina?.childImageSharp?.fluid} />
            </header>
            <main className={style.copy}>
              <p>
                Kristina delivers Right of Way Acquisition,
                Relocation, Project Management and Staff Training for
                RES Group NW. She brings enthusiasm and perseverance
                in successfully completing acquisitions and
                relocations with respect for those impacted and the
                interest of the public. Her commitment to hard work
                and excellence, attention to detail, natural ability
                to communicate makes her an asset on any given
                team. Kristina’s experience in the right of way
                industry is predominately under the guidelines of the
                Federal Uniform Relocation Assistance and Real
                Property Acquisition Policies Act (URA).
              </p>
            </main>
            <footer>
              <ul className={style.certs}>
                <li>BS, Law, Society, and Justice</li>
                <li>Real Estate Broker</li>
                <li>SR/WA Senior R/W Professional</li>
                <li>WSDOT Approved Consultant</li>
              </ul>
            </footer>
          </article>
        </section>
    )
}

export default Team
