import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Img from "gatsby-image"

import style from "./styles/introduction.module.scss"

const Introduction = () => {
    const data = useStaticQuery(graphql`
      query {
        dbe: file(relativePath: { eq: "cert--dbe.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        women: file(relativePath: { eq: "cert--women.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        wsdot: file(relativePath: { eq: "cert--wsdot.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        realEstate: file(relativePath: { eq: "cert--real-estate.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        background: file(relativePath: { eq: "header-background.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    return (
        <section id="introduction" className={style.component}>
          <div className={style.background}>
            <Img className={style.backgroundImage}
                 alt="Background image of Seattle"
                 fluid={data?.background?.childImageSharp?.fluid} />
          </div>
          <h1 className={style.tagline}>Comprehensive <br /> Right-of-way Solutions</h1>
          <p className={style.copy}>
            RES Group Northwest is a woman-owned real estate services
            consulting firm specializing in public infrastructure and
            private development projects involving acquisition,
            relocation assistance, and project management.
          </p>
          <dl className={style.certs}>
            <div className={style.cert}>
              <Img className={style.icon} alt="Icon" fluid={data?.dbe?.childImageSharp?.fluid}/>
              <dt>Federal Disadvantaged Business Enterprise</dt>
              <dd>D4F0023741</dd>
            </div>
            <div className={style.cert}>
              <Img className={style.icon} alt="Icon" fluid={data?.women?.childImageSharp?.fluid}/>
              <dt>Cert. Minority/Women's Business Enterprise</dt>
              <dd>M4F0023741</dd>
            </div>
            <div className={style.cert}>
              <Img className={style.icon} alt="Icon" fluid={data?.wsdot?.childImageSharp?.fluid}/>
              <dt>WSDOT Approved/Audited ICR Rate</dt>
              <dd></dd>
            </div>
            <div className={style.cert}>
              <Img className={style.icon} alt="Icon" fluid={data?.realEstate?.childImageSharp?.fluid}/>
              <dt>Washington State Real Estate License</dt>
              <dd>19350</dd>
            </div>
          </dl>
        </section>
    )
}

export default Introduction
