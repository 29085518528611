import { useStaticQuery, graphql, Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

import style from "./styles/projects.module.scss"

const Project = ({ client, project, children, path, logo }) => {
    return (
        <article className={style.project}>
          <header>
            <h3 className={style.client}>{client}</h3>
            <p className={style.projectName}>{project}</p>
            <Img className={style.icon} alt={`${client} icon`} fluid={logo}/>
          </header>
          <main className={style.main}>
            {children}
          </main>
          <footer className={style.moreLinkContainer}>
            <Link className={style.moreLink} to={path}>Read more about this project</Link>
          </footer>
        </article>
    )
}

const Projects = () => {

    const data = useStaticQuery(graphql`
      query {
        desMoines: file(relativePath: { eq: "logo--des-moines.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        kenmore: file(relativePath: { eq: "logo--kenmore.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        everson: file(relativePath: { eq: "logo--everson.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        wsdot: file(relativePath: { eq: "logo--wsdot.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        soundTransit: file(relativePath: { eq: "logo--sound-transit.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
        chelanAirport: file(relativePath: { eq: "logo--chelan-airport.png" }) {
          childImageSharp {fluid { ...GatsbyImageSharpFluid} } }
      }
    `)

    return (
        <div id="projects" className={style.component}>
          <section className={style.projectClass}>
            <header>
              <h2>Right-of-way</h2>
              <main className={style.projects}>
                <Project
                  client="City of Des Moines"
                  project="Transportation Gateway"
                  path="/des-moines"
                  logo={data?.desMoines?.childImageSharp?.fluid}>
                 <p>
                    A multi-phase project to upgrade arterial streets for
                    planned commercial and residential development. RES
                    Group has taken two of three phases through WSDOT
                    certification and performed as an expert witness for
                    condemnation proceedings.
                  </p>
                </Project>
                <Project
                  client="City of Kenmore"
                  project="West Sammamish Bridge"
                  path="/kenmore"
                  logo={data?.kenmore?.childImageSharp?.fluid}>
                  <p>
                    Project to replace an existing two-lane bridge
                    and add mixed-use bicycle and pedestrian
                    paths. This project includes relocation and a
                    complex 6(f) exchange with WA Fish and Wildlife.
                  </p>
                </Project>
                <Project
                  client="City of Everson"
                  project="Lincoln Street / Kirsch Drive"
                  path="/everson"
                  logo={data?.everson?.childImageSharp?.fluid}>
                  <p>
                    Widening an existing roadway to install bike lanes,
                    curbs, gutters, and sidewalks. RES Group completed PFE
                    and relocation plans with partial fee acquisitions
                    from commercial and residential parcels and completion
                    of WSDOT certification.
                  </p>
                </Project>
              </main>
            </header>
          </section>
          <section className={style.projectClass}>
            <header>
              <h2>Relocation</h2>
              <main className={style.projects}>
                <Project
                  client="WSDOT"
                  project="Gateway Completion"
                  path="/wsdot"
                  logo={data?.wsdot?.childImageSharp?.fluid}>
                  <p>
                    A project to extend SR-509 to relieve congestion,
                    improve access to Sea-Tac Airport and between
                    industrial zones. RES Group managed the overall
                    relocation process while actively performing 120
                    relocations for non-residential and residential
                    displacees.
                  </p>
                </Project>
                <Project
                  client="Sound Transit"
                  project="East Link"
                  path="/sound-transit"
                  logo={data?.soundTransit?.childImageSharp?.fluid}>
                  <p>
                    RES Group managed the acquisition and relocation of
                    hundreds of commercial and residential parcels to make
                    way for Sound Transit's extension of Link Light Rail
                    to Bellevue and other Eastside destinations.
                  </p>
                </Project>
                <Project
                  client="Lake Chelan Airport"
                  project="Airport Improvement"
                  path="/chelan-airport"
                  logo={data?.chelanAirport?.childImageSharp?.fluid}>
                  <p>
                    This project involved acquiring land to comply with
                    FAA order 5100.38C for flight safety and compatible
                    land use. RES Group managed one business, one
                    residential, and two personal property relocations
                    on-time and in-budget.
                  </p>
                </Project>
              </main>
            </header>
          </section>
        </div>
    )
}

export default Projects
