import React from "react"

import style from "./styles/contact.module.scss"

const Contact = () => (
    <section id="contact" className={style.component}>
      <header className={style.header}>
        <h2>Contact us</h2>
      </header>
      <main className={style.main}>
        <h3>SODO</h3>
        <address>
          624 S Lander Street, #202<br />
          Seattle, WA 98134
        </address>
        <h3>Eastside</h3>
        <address>
          1913 6th Street<br />
          Kirkland, WA 98033
        </address>
        <h3>Phone</h3>
        <address>
          <a className={style.fullUnderline} href="tel:+12064575681">(206) 457 5681</a>
        </address>
        <a className={style.mailLink} href="mailto:office@resgnw.com">Send an Email</a>
      </main>
      <aside className={style.map} />
    </section>
)

export default Contact
