import React, { useState } from "react"

import style from "./styles/why.module.scss"

const Why = () => {
    const [active, setActive] = useState(false)
    return (
        <section id="why" className={style.component} data-isactive={active}>
          <main>
            <div className={style.group}>
              <h2>Why choose us</h2>
              <p>
                We have the experience and resources to understand and
                execute public project land requirements, including
                the successful acquisition of property and relocation
                of business and residential occupants.  We practice
                thorough and effective coordination with the agencies
                from start of planning and throughout the process as
                we complete acquisition services for important public
                projects.  This along with our ability to communicate
                amicably and professionally with property owners
                maintains project integrity and keeps transactions
                moving forward.
              </p>
            </div>
            <div className={style.group}>
              <h2>Our mission</h2>
              <p>
                RES Group NW is committed to providing the Prime and
                the Agency with a team of experienced professionals
                dedicated to delivering real estate services that are
                comprehensive, reliable, and congruent with the
                project needs and goals.
              </p>
            </div>
            <div className={style.group}>
              <h2>What we do</h2>
              <p>
                Provide all services necessary for successful
                acquisitions and relocations including project funding
                estimates, relocation planning, project management,
                title evaluation, and WSDOT certification.  Our
                comprehensive knowledge of local, state and federal
                laws and administrative regulations, coupled with our
                reputation for creative solutions, enables us to work
                cooperatively with the Agency’s staff to find simple
                and cost-effective ways to accomplish any objectives.
              </p>
            </div>
          </main>
          <footer className={style.expander}>
            <div tabindex={0} className={style.expanderButton} role="button" onClick={() => {
                setActive(!active)
            }} onKeyDown={() => {
                setActive(!active)
            }} />
          </footer>
        </section>
    )
}

export default Why
