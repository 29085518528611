import React from "react"

import style from "./styles/testimonials.module.scss"

const Testimonials = () => (
    <section id="testimonials" className={style.component}>
      <header>
        <h2>What clients say</h2>
      </header>
      <main className={style.container}>
        <blockquote>
          <cite>
            <h3 className={style.name}>Barbara Bulzomi</h3>
            <p className={style.organization}>Fmr. WSDOT Acquisition Supervisor</p>
          </cite>
          <p className={style.quote}>
            Highly skilled in acquisition and relocation. As a
            contractor for WSDOT she was relied on and highly
            successful in completing difficult acquisitions and
            complex relocations with compressed schedules.
          </p>
        </blockquote>
        <blockquote>
          <cite>
            <h3 className={style.name}>John McKenzie, P.E.</h3>
            <p className={style.organization}>Jacobs, Senior Project Manager</p>
          </cite>
          <p className={style.quote}>
            I have worked the RES team on several urban corridor
            projects, and I consistently find them incredibly
            knowledgeable and able to tackle the most challenging
            property acquisition issues, especially on federally
            funded projects.
          </p>
        </blockquote>
        <blockquote>
          <cite>
            <h3 className={style.name}>John Vicente, P.E.</h3>
            <p className={style.organization}>City Engineer, Kenmore</p>
          </cite>
          <p className={style.quote}>
            Their knowledge, honesty, and persistence helped 
            make our projects successful and without surprises.
          </p>
        </blockquote>
      </main>
    </section>
)

export default Testimonials
