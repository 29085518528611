import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Introduction from "../components/introduction"
import Why from "../components/why"
import Projects from "../components/projects"
import Testimonials from "../components/testimonials"
import Team from "../components/team"
import Contact from "../components/contact"


const IndexPage = () => (
    <Layout>
      <SEO title="Home" />
        <Introduction />
        <Why />
        <Projects />
        <Testimonials />
        <Team />
        <Contact />
    </Layout>
)

export default IndexPage
